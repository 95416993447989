import * as React from 'react'

interface IProp {
  arrCount: number
  index: number
}

const ConcatWords: React.FC<IProp> = ({ arrCount, index, children }) => {
  if (arrCount === 1) {
    return <>{children}</>
  }

  if (index + 2 < arrCount) {
    return <>{children}, </>
  }

  if (index + 1 === arrCount) {
    return (
      <>
        {' '}
        {'&'} {children}
      </>
    )
  }

  return <>{children}</>
}

export default ConcatWords
