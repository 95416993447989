import { Flex } from '../../shared/Helpers'
import styled, { css } from '../../styled'

export const FlexColumn = styled(Flex)`
  flex-flow: row wrap;
  justify-content: space-between;

  &:after {
    content: '';

    ${({ theme: { breakpoints } }) => css`
      @media screen and (min-width: ${breakpoints[0]}) {
        display: none;
      }

      @media screen and (min-width: ${breakpoints[1]}) {
        display: block;
        width: calc((100% / 2) - 0.7em);
      }

      @media screen and (min-width: ${breakpoints[1]}) {
        display: block;
        width: calc((100% / 3) - 0.7em);
      }
    `}
  }
`
