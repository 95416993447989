import Button from '../shared/Button'
import styled, { css } from '../styled'

export const ImageNewsletter = styled.div`
  ${({ theme: { breakpoints } }) =>
    css`
      @media screen and (max-width: ${breakpoints[0]}) {
        margin-left: -8em;
        max-width: none;
        width: 120%;
      }

      @media screen and (min-width: ${breakpoints[0]}) {
        margin-left: -8em;
        margin-top: 2em;
        max-width: 400px;
      }
    `};
`

export const Submit = styled(Button)`
  height: 2.9rem;
  line-height: 0.8rem;
  margin-right: 0;
  flex: 0.1;

  ${({ theme: { breakpoints } }) =>
    css`
      width: 100%;
      flex: auto;

      svg {
        display: none;
      }

      @media screen and (min-width: ${breakpoints[0]}) {
        width: auto;
        
        svg {
          display: block;
        }

        .button-text {
          display: none;
        }
      `};
`
