import * as React from 'react'
import { space, width } from 'styled-system'

import styled, { css } from '../../styled'
import { Box } from '../Helpers'
import { IProp, IPropsTypes } from './types'

function errorStyle(props: IProp) {
  const {
    error,
    theme: { colors },
  } = props

  if (error) {
    return css`
      border: 1px solid ${colors.red.default};
    `
  }

  return null
}

export const inputStyle = css<IProp>`
  width: 100%;
  line-height: 1;
  border-radius: 3px;
  padding: 0.9rem 0.75rem 0.6rem;
  background-color: ${({ theme: { colors } }) => colors.grayscale.white};
  border: 1px solid ${({ theme: { colors } }) => colors.grayscale.light};
  transition: border ${({ theme: { transition } }) => transition.ease()};

  ${errorStyle}
  ${space}
  ${width}

  &::-webkit-input-placeholder {
    font-weight: normal;
    color: ${({ theme: { colors } }) => colors.grayscale.default}
  }
  &::-moz-placeholder {
    font-weight: normal;
    color: ${({ theme: { colors } }) => colors.grayscale.default}
  }
  &:-ms-input-placeholder {
    font-weight: normal;
    color: ${({ theme: { colors } }) => colors.grayscale.default}
  }
  &:-moz-placeholder {
    font-weight: normal;
    color: ${({ theme: { colors } }) => colors.grayscale.default}
  }

  &:hover {
    border: 1px solid
      ${({ theme: { colors } }) => colors.grayscale.mediumLighter};
  }

`

const Input = styled.input<IProp>`
  ${inputStyle};
`

const ErrorHolder = styled.div<IProp>`
  ${space}
  color: ${({ theme: { colors } }) => colors.red.default};
`

export default function InputWrapper({
  containerProps,
  name,
  placeholder,
  onChange,
  ...props
}: IPropsTypes) {
  return (
    <Box {...containerProps}>
      <Input
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
      {props.error && <ErrorHolder mt="10px">{props.error}</ErrorHolder>}
    </Box>
  )
}
