import * as React from 'react'

import { endpoint } from '../../constants'
import Arrow from '../../images/icons/arrow'
import Card from '../shared/Card'
import { Container } from '../shared/Grid'
import { Box, Flex } from '../shared/Helpers'
import { Image } from '../shared/Image'
import Input from '../shared/Input'
import { Description, SubTitle, Text } from '../shared/Typography'
import { ImageNewsletter, Submit } from './parts'
import { IProps, IState } from './types'

const checkPath = require('../../images/icons/check.svg')

const formatError = (msg: string, email: string): React.ReactNode => {
  if (msg === 'Member Exists') {
    return <p>{email} is already subscribed.</p>
  }

  if (/has too many recent signup/.test(msg)) {
    return <p>Recipient {email} has too many recent signup requests</p>
  }

  return false
}

export default class Newsletter extends React.Component<IProps, IState> {
  state = {
    disabled: true,
    email: '',
    errorMessage: '',
    name: '',
    status: '',
  }

  handleValidation = () => {
    const { name, email } = this.state
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

    if (name.length === 0 || !emailRegex.test(email)) {
      return this.setState({ disabled: true })
    }

    return this.setState({ disabled: false })
  }

  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget
    this.setState({ [name]: value }, this.handleValidation)
  }

  onSubmit = () => {
    const { name, email } = this.state
    const url = `${endpoint}/newsletter`

    this.setState({ status: 'sending' })

    fetch(url, {
      body: JSON.stringify({ name, email }),
      method: 'POST',
    })
      .then(response => response.json())
      .then(r => {
        if (r.status === 400) {
          return this.setState({
            errorMessage: formatError(r.title, this.state.email),
            status: 'error',
          })
        }

        return this.setState({
          status: 'success',
        })
      })
      .catch(err => {
        this.setState({
          errorMessage: formatError(err.title, this.state.email),
          status: 'error',
        })
      })
  }

  render() {
    const { data } = this.props
    const { disabled, name, email, status, errorMessage } = this.state

    const isSending = status === 'sending'
    const isError = status === 'error'
    const isSuccess = status === 'success'

    const style = {
      cols: {
        ml: [null, 0, '12em'],
        pb: ['0', '9em'],
        pt: ['1em', '9em'],
        width: ['100%', '70%'],
      },
      inputs: {
        mb: '1em',
        mr: ['0', '1em'],
        width: '100%',
      },
    }

    return (
      <Container id="newsletter">
        <form>
          <Flex flexWrap={['wrap', 'nowrap']} mb={['5em', '2em']}>
            <Box {...style.cols}>
              <SubTitle>{data.title}</SubTitle>
              <Description mb="1em" color="grey" id="newsletter--description">
                {data.description}
              </Description>
              {isSuccess ? (
                <Card p="1em">
                  <Text color="blue">
                    <Flex as="span" alignItems="center">
                      <Box as="span" mr=".6em" mt="-.1em">
                        <img src={checkPath} alt={data.successMessage} />
                      </Box>
                      {data.successMessage}
                    </Flex>
                  </Text>
                </Card>
              ) : (
                <Flex flexWrap={['wrap', 'nowrap']}>
                  <Input
                    containerProps={style.inputs}
                    name="name"
                    onChange={this.handleChange}
                    placeholder="Your name"
                    value={name}
                    aria-labelledby="newsletter--description"
                  />
                  <Input
                    containerProps={style.inputs}
                    name="email"
                    onChange={this.handleChange}
                    placeholder="Email address"
                    type="email"
                    value={email}
                    aria-labelledby="newsletter--description"
                  />

                  <Submit
                    disabled={disabled || isSending}
                    onClick={this.onSubmit}
                    type="submit"
                  >
                    <Arrow direction="right" />
                    <span className="button-text">{data.submit}</span>
                  </Submit>
                </Flex>
              )}
              <Box minWidth="100%">
                <Text color="red">
                  {isError && (errorMessage || data.errorMessage)}
                </Text>
              </Box>
            </Box>
            <Box width={['100%', '40%']} order={[-1, 0]} zIndex={-2}>
              <ImageNewsletter>
                <Image path="newsletter" alt={data.title} />
              </ImageNewsletter>
            </Box>
          </Flex>
        </form>
      </Container>
    )
  }
}
