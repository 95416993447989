import { ICalendarMessages } from '../components/shared/CalendarApi/types'

export const newsletterUrl = `https://world.us11.list-manage.com/subscribe/post-json?u=6a89aaaced04690a914e4f8ef&amp;id=e713631667`

export const googleCalendarIds: ICalendarMessages = {
  general: process.env.GATSBY_CALENDAR_GENERAL_ID || '',
  hub: process.env.GATSBY_CALENDAR_HUB_ID || '',
}

export const endpoint =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:9000'
    : 'https://akasha.org/.netlify/functions'
