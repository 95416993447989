import { Box } from '../../shared/Helpers'
import { FootNote } from '../../shared/Typography'
import styled from '../../styled'

export const GhostButton = styled.button`
  text-align: inherit;
`

export const Image = styled(Box)`
  margin: -1.5em -1.5em 1.5em;
  padding-top: 60%;
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ${({ theme: { transition } }) => transition.cubic()};
  }
`

export const Wrapper = styled.div`
  &:hover {
    img {
      transform: scale(1.02);
    }

    ${FootNote} a {
      color: ${({ theme: { colors } }) => colors.grayscale.dark};
    }
  }
`

export const CategoryTag = styled.div`
  background: ${({ theme: { colors } }) => colors.blue.light};
  border-radius: 2px;
  color: ${({ theme: { colors } }) => colors.blue.default};
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1;
  margin-right: 0.5em;
  padding: 0.5em 0.5em 0.3em;
  text-transform: uppercase;
`

export const VideoResponsive = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
