import { Link } from 'gatsby'
import * as React from 'react'

import ConcatWords from '../../../utils/ConcatWords'
import {
  generateAuthorUrl,
  generateCategoryUrl,
} from '../../../utils/generateUrl'
import Card from '../../shared/Card'
import { Flex } from '../../shared/Helpers'
import { Caption, FootNote, Text } from '../../shared/Typography'
import {
  CategoryTag,
  Image,
  Wrapper,
  VideoResponsive,
  GhostButton,
} from './parts'
import { IProp } from './types'
import Modal from '../../shared/Modal'

export default function BlogCard({ data, onlyCard = false, ...props }: IProp) {
  const [showModal, setShowModal] = React.useState(false)

  const isVideo = data.videoId
  const handleClick = () => {
    if (data.videoId) {
      setShowModal(true)
    }
  }

  const content = (
    <>
      {data.category.map(cat => (
        <CategoryTag key={cat}>
          <Link to={generateCategoryUrl(cat)}>{cat}</Link>
        </CategoryTag>
      ))}

      {isVideo ? (
        <GhostButton onClick={handleClick}>
          <Caption my=".5em">{data.title}</Caption>
          <Text mb="1em">{data.excerpt}</Text>
        </GhostButton>
      ) : (
        <Link to={data.slug!}>
          <Caption my=".5em">{data.title}</Caption>
          <Text mb="1em">{data.excerpt}</Text>
        </Link>
      )}

      <Flex justifyContent="space-between">
        {data.author.length > 0 && (
          <FootNote color="greyMedium" pr="2em">
            By{' '}
            {data.author.map((aut, index) => (
              <ConcatWords
                arrCount={data.author.length}
                index={index}
                key={aut}
              >
                <Link to={generateAuthorUrl(aut)}>{aut}</Link>
              </ConcatWords>
            ))}
          </FootNote>
        )}
        <FootNote style={{ whiteSpace: 'nowrap' }} color="greyMedium">
          {data.date}
        </FootNote>
      </Flex>
    </>
  )

  const image = data.hero.childImageSharp

  const renderImage = () => {
    if (!onlyCard && 'fluid' in image) {
      if (data.slug) {
        return (
          <Image>
            <Link to={data.slug!}>
              <span>
                <img {...image.fluid} alt={data.title} />
              </span>
            </Link>
          </Image>
        )
      }

      if (isVideo) {
        return (
          <Image>
            <button onClick={handleClick}>
              <span>
                <img {...image.fluid} alt={data.title} />
              </span>
            </button>
          </Image>
        )
      }
    }

    return null
  }

  return (
    <Card p="1.5em" {...props}>
      <Wrapper>
        {renderImage()}
        {content}
      </Wrapper>

      {showModal && data.videoId && (
        <Modal full onClickOutside={() => setShowModal(false)}>
          <VideoResponsive>
            <iframe
              src={`https://www.youtube.com/embed/${data.videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </VideoResponsive>
        </Modal>
      )}
    </Card>
  )
}
