import * as React from 'react'

import BlogCard from '../Card'
import { FlexColumn } from './parts'

interface IProp {
  posts: Array<{ node: IQueryBlog }>
}

export default function blogList({ posts }: IProp) {
  if (!posts || posts.length === 0) {
    return null
  }

  return (
    <FlexColumn>
      {posts.map(({ node }) => {
        let data: IBlog = {
          ...node.frontmatter,
          excerpt: node.excerpt,
          slug: node.fields.slug,
        }

        if (node.frontmatter.videoId && node.fields.videoSnippet) {
          data = {
            ...data,
            author: [],
            excerpt:
              node.fields.videoSnippet.description.substring(0, 100) + '...',
            hero: {
              childImageSharp: {
                fluid: { src: node.fields.videoSnippet.thumbnails.high.url },
              },
            },
            slug: undefined,
            title: node.fields.videoSnippet.title,
          }
        }

        return (
          <BlogCard
            mb={['2em', '4em']}
            width={['100%', 'calc((100% / 2) - 1em)', 'calc((100% / 3) - 1em)']}
            key={node.fields.slug}
            data={data}
          />
        )
      })}
    </FlexColumn>
  )
}
