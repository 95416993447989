import * as React from 'react'

import styled, { createGlobalStyle, keyframes } from '../../styled'

const fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fade} 0.15s linear;
`

const ModalCard = styled.div<{ full: boolean }>`
  background: white;
  width: ${({ full }) => (full ? '70em' : '550px')};
  max-height: 100%;
  overflow: auto;
  border-radius: 4px;
`

const BlockBody = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`

interface IProps {
  children?: React.ReactNode
  onClickOutside: () => any
  full?: boolean
  [key: string]: any
}

export default function Modal(props: IProps) {
  const { children, onClickOutside, full = false, ...restProps } = props
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  return (
    <ModalContainer
      onClick={event => {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          onClickOutside()
        }
      }}
    >
      <BlockBody />
      <ModalCard full={full} ref={wrapperRef}>
        <div {...restProps}>{children}</div>
      </ModalCard>
    </ModalContainer>
  )
}
