import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../utils/mergeEdges'
import { IProp } from './types'
import View from './view'

export default function queryNewsletter() {
  return (
    <StaticQuery
      query={graphql`
        query Newsletter {
          allContentYaml {
            edges {
              node {
                newsletter {
                  title
                  description
                  submit
                  successMessage
                  errorMessage
                }
              }
            }
          }
        }
      `}
      render={({ allContentYaml }: IProp) => {
        const data = mergeEdges(allContentYaml)

        if (!data) {
          return null
        }

        return <View data={data.newsletter} />
      }}
    />
  )
}
